import { Link } from "gatsby"
import BlogPostMediaPreview from "./BlogPostMediaPreview"
import * as React from "react"
import { CommentCount } from "gatsby-plugin-disqus"

const BlogPostItem = ({ post }) => {
  let disqusConfig = {
    // url: `${config.siteUrl + location.pathname}`,
    url: `http://gorigins.com/blog${post.slug}`,
    identifier: post.id,
    title: post.title,
  }
  return (
    <section className="hidden-section article bot-element">
      <div className="col-wc_dec" />
      <div className="container">
        <div className="section-title fl-wrap post-title">
          <h3>{post.title}</h3>
          <div className="post-header">
            {" "}
            <a href="#">{post.createdAt}</a>
            <span>Category : </span>
            {post.categories?.map(category => (
              <Link key={category.id} to={`/blog/categories/${category.slug}`}>
                {category.name}
              </Link>
            ))}
          </div>
        </div>
        {/*<!-- media-wrap-->*/}
        <BlogPostMediaPreview post={post} />
        {/*<!-- media-wrap end -->*/}
        <div className="post-block fl-wrap">
          <div className="post-opt fl-wrap">
            <ul>
              <li>
                <a href="#">
                  <i className="fal fa-user" /> Jerez Bain
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="fal fa-comments-alt" />{" "}
                  <CommentCount config={disqusConfig} placeholder={"..."} />
                </a>
              </li>
              {/*<li>
              <span>
                <i className="fal fa-eye"></i> 123 view
              </span>
            </li>*/}
            </ul>
          </div>
          <p>{post.excerpt?.excerpt}</p>
          <Link to={`/blog/${post.slug}`} className="btn ajax fl-btn color-bg">
            Read more
          </Link>
        </div>
        {/*<div className="sec-number">01.</div>*/}
      </div>
    </section>
  )
}

export default BlogPostItem
