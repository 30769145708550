import * as React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql, Link } from "gatsby"
import ContentWrapper from "../components/Shared/ContentWrapper"
import BlogPostItem from "../components/Blog/BlogPostItem"
import BlogPagination from "../components/Blog/BlogPagination"

const BlogCategory = ({ data }) => {
  const categoryData = data.contentfulCategory
  const { nodes: allCategories } = data.allContentfulCategory
  const { nodes: allTags } = data.allContentfulTag

  return (
    <Layout title={`Blog: ${categoryData.name}`}>
      <SEO title={`Blog: ${categoryData.name}`} />
      <div className="fixed-column-wrap">
        <div className="progress-bar-wrap">
          <div className="progress-bar color-bg" />
        </div>
        <div className="column-image fl-wrap full-height">
          <div className="bg" data-bg="images/bg/long/1.jpg"></div>
          <div className="overlay" />
          <div className="column-image-anim" />
        </div>
        <div className="fcw-dec" />
        <div className="fixed-column-tilte fcw-title">
          <span>Last News</span>
        </div>
      </div>
      <ContentWrapper nav={null}>
        {/*<!-- fixed-top-panel-->*/}
        <div className="fixed-top-panel fl-wrap">
          <div className="sp-fix-header fl-wrap">
            <div className="scroll-down-wrap hide_onmobile">
              <div className="mousey">
                <div className="scroller" />
              </div>
              <span>Scroll down to discover</span>
            </div>
            {/*<div className="search-btn">
              <i className="fal fa-search" />
              <span>Search</span>
            </div>*/}
            {/*<!-- filter tag   -->*/}
            <div className="tag-filter blog-btn-filter">
              <div className="blog-btn">
                Tags <i className="fa fa-tags" aria-hidden="true" />
              </div>
              <ul>
                {allTags.map(tag => (
                  <li key={tag.id}>
                    <Link
                      to={`/blog/categories/${tag.slug}`}
                      activeClassName="active"
                    >
                      {tag.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            {/*<!-- filter tag end  -->*/}
            {/*<!-- filter category    -->*/}
            <div className="category-filter blog-btn-filter">
              <div className="blog-btn">
                Categories <i className="fa fa-list-ul" aria-hidden="true" />
              </div>
              <ul>
                {allCategories.map(category => (
                  <li key={category.id}>
                    <Link
                      to={`/blog/categories/${category.slug}`}
                      activeClassName="active"
                    >
                      {category.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            {/*<!-- filter category end  -->*/}
          </div>
          {/*<div className="blog-search-wrap">
            <input
              name="se"
              id="se"
              type="text"
              className="search"
              placeholder="Type and click Enter to search.."
              value=""
            />
          </div>*/}
        </div>
        {/*<!-- fixed-top-panel end -->*/}

        {/*<!--section -->*/}
        {Array.isArray(categoryData.blog_post) &&
          categoryData.blog_post.map(post => (
            <BlogPostItem key={post.id} post={post} />
          ))}
        {/*<!--section end -->*/}
        <div className="section-separator bot-element">
          <span className="fl-wrap" />
        </div>
        {/*<!--section -->*/}

        {/*<!--pagination-->*/}
        {/*<BlogPagination pageInfo={pageInfo} />*/}
        {/*<!--pagination end-->*/}
        <div className="limit-box fl-wrap" />
      </ContentWrapper>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    allContentfulCategory {
      nodes {
        id
        name
        slug
      }
    }
    allContentfulTag {
      nodes {
        id
        name
        slug
      }
    }
    contentfulCategory(slug: { eq: $slug }) {
      id
      name
      slug
      blog_post {
        slug
        title
        id
        createdAt(fromNow: true)
        categories {
          id
          name
          slug
        }
        tags {
          id
          name
          slug
        }
        featuredImage {
          file {
            url
          }
        }
        excerpt {
          excerpt
        }
      }
    }
  }
`

export default BlogCategory
