import * as React from "react"
import { Link } from "gatsby"

const BlogPostMedia = ({ post }) => {
  switch (post.postType) {
    case "Gallery":
      return (
        <div className="media-wrap lightgallery fl-wrap">
          <div className="single-slider-wrap">
            <div className="single-slider fl-wrap">
              <div className="swiper-container">
                <div className="swiper-wrapper lightgallery">
                  <div className="swiper-slide hov_zoom">
                    <img src="images/slider/1.jpg" alt="" />
                    <a
                      href="images/slider/1.jpg"
                      className="box-media-zoom   popup-image"
                    >
                      <i className="fal fa-search"></i>
                    </a>
                  </div>
                  <div className="swiper-slide hov_zoom">
                    <img src="images/slider/1.jpg" alt="" />
                    <a
                      href="images/slider/1.jpg"
                      className="box-media-zoom   popup-image"
                    >
                      <i className="fal fa-search"></i>
                    </a>
                  </div>
                  <div className="swiper-slide hov_zoom">
                    <img src="images/slider/1.jpg" alt="" />
                    <a
                      href="images/slider/1.jpg"
                      className="box-media-zoom   popup-image"
                    >
                      <i className="fal fa-search"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="ss-slider-cont ss-slider-cont-prev  ">
                <i className="fal fa-long-arrow-left"></i>
              </div>
              <div className="ss-slider-cont ss-slider-cont-next  ">
                <i className="fal fa-long-arrow-right"></i>
              </div>
              <div className="ss-slider-controls">
                <div className="ss-slider-pagination"></div>
              </div>
            </div>
          </div>
        </div>
      )

    case "Default":
    default:
      return (
        <div className="media-wrap fl-wrap">
          <img
            src={post.featuredImage.file.url}
            alt={post.title}
            className="respimg"
          />
        </div>
      )
  }
}

export default BlogPostMedia
