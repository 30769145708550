import * as React from "react"
interface ContentWrapperProps {
  children: any
  nav: any
  contentClasses?: string
}
const ContentWrapper = ({ children, nav, contentClasses = "" }) => {
  return (
    <div className="column-wrap">
      {/*<!-- content -->*/}
      <div className={`content ${contentClasses}`}>
        {/*<!-- page-scroll-nav -->*/}
        {nav}
        {/*<!-- page-scroll-nav end -->*/}
        {children}
        <div className="limit-box fl-wrap" />
      </div>
    </div>
  )
}

export default ContentWrapper
